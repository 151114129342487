<template>
  <div>
    <Pane>
      <template v-slot:extra>
        <a-space style="margin-top: 12px; margin-right: -16px">
          <a-month-picker
            :allowClear="false"
            style="width: 120px"
            v-model="currentMonth"
          />
        </a-space>
      </template>
    </Pane>

    <div class="container">
      <a-card class="card">
        <div class="header">
          <div class="title">全院数据</div>
        </div>

        <Analysis
          :list="[
            {
              title: '年假人数',
              value: yearNum,
            },
            {
              title: '请假人数',
              value: holidayNum,
            },
            {
              title: '出差人数',
              value: businessNum,
            },
            {
              title: '公出人数',
              value: outNum,
            },

            {
              title: '年假时长',
              value: yearLength,
            },
            {
              title: '请假时长',
              value: holidayLength,
            },
            {
              title: '出差时长',
              value: businessLength,
            },
            {
              title: '公出时长',
              value: outLength,
            },
          ]"
        />
      </a-card>

      <Padding />

      <a-card class="card">
        <div class="header">
          <div class="title">各部门请假出差人数</div>

          <div class="extra">
            <a href="#" @click.prevent="downloadNumChart">导出</a>
          </div>
        </div>
        <HolidayNumChart :list="depts" />
      </a-card>

      <Padding />

      <a-card class="card">
        <div class="header">
          <div class="title">各部门请假出差时长</div>
          <div class="extra">
            <a href="#" @click.prevent="downloadLengthChart">导出</a>
          </div>
        </div>
        <HolidayLengthChart :list="depts" />
      </a-card>

      <Padding />

      <HolidayTable :list="list" :month="currentMonth.format('M')" />

      <Padding />

      <HolidayUserTable :list="users" :month="currentMonth.format('M')" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchLeave } from "@/api/human-resources/clock";

import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";

import Analysis from "./components/analysis.vue";
import HolidayNumChart from "./components/holiday-num-chart.vue";
import HolidayLengthChart from "./components/holiday-length-chart.vue";
import HolidayTable from "./components/holiday-table.vue";
import HolidayUserTable from "./components/holiday-user-table.vue";

export default {
  components: {
    Analysis,
    HolidayNumChart,
    HolidayLengthChart,
    HolidayTable,
    HolidayUserTable,
  },

  data() {
    return {
      currentMonth: moment(),

      yearNum: 0,
      holidayNum: 0,
      businessNum: 0,
      outNum: 0,
      yearLength: 0,
      holidayLength: 0,
      businessLength: 0,
      outLength: 0,

      list: [],
      depts: [],
      users: [],
    };
  },

  watch: {
    currentMonth() {
      this.getData();
    },
  },

  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      const startTime =
        this.currentMonth.startOf("month").format("YYYY-MM-DD") + " 00:00:00";
      const endTime =
        this.currentMonth.endOf("month").format("YYYY-MM-DD") + " 23:59:59";

      const hide = this.$message.loading("加载中...", 0);

      fetchLeave({
        startTime,
        endTime,
        statusList: ["reviewing", "approved"],
      })
        .then((res) => {
          if (Array.isArray(res)) {
            this.list = Object.freeze(
              res.filter((item) => item.optType !== "cancel")
            );

            const on_business_out = this.list.filter(
              (item) => item.type === "on_business_out"
            );
            const on_business_in = this.list.filter(
              (item) => item.type === "on_business_in"
            );
            const annual = this.list.filter((item) => item.type === "annual");
            const leaves = this.list.filter(
              (item) =>
                item.type !== "on_business_out" &&
                item.type !== "on_business_in" &&
                item.type !== "annual"
            );

            this.businessNum = on_business_out
              .map((item) => item.userId)
              .filter(
                (item, index, self) => self.indexOf(item) === index
              ).length;
            this.outNum = on_business_in
              .map((item) => item.userId)
              .filter(
                (item, index, self) => self.indexOf(item) === index
              ).length;
            this.yearNum = annual
              .map((item) => item.userId)
              .filter(
                (item, index, self) => self.indexOf(item) === index
              ).length;
            this.holidayNum = leaves
              .map((item) => item.userId)
              .filter(
                (item, index, self) => self.indexOf(item) === index
              ).length;

            let businessLength = 0;
            let outLength = 0;
            let yearLength = 0;
            let holidayLength = 0;

            on_business_out.forEach((item) => {
              businessLength += item.duration;
            });
            on_business_in.forEach((item) => {
              if (item.durationUnit === "day") {
                outLength += item.duration;
              } else if (item.durationUnit === "hour") {
                outLength += item.duration / 24;
              }
            });
            outLength = parseInt(outLength);
            annual.forEach((item) => {
              yearLength += item.duration;
            });
            leaves.forEach((item) => {
              holidayLength += item.duration;
            });

            this.businessLength = businessLength;
            this.outLength = outLength;
            this.yearLength = yearLength;
            this.holidayLength = holidayLength;

            const depts = [];
            this.list.forEach((item) => {
              const obj = depts.find(
                (element) => element.deptName === item.deptName
              );
              if (obj) {
                if (item.type === "on_business_out") {
                  obj.businessNum.push(item.userId);
                  obj.businessLength += item.duration;
                } else if (item.type === "on_business_in") {
                  obj.outNum.push(item.userId);

                  if (item.durationUnit === "day") {
                    obj.outLength += item.duration;
                  } else if (item.durationUnit === "hour") {
                    obj.outLength += item.duration / 24;
                  }
                } else if (item.type === "annual") {
                  obj.yearNum.push(item.userId);
                  obj.yearLength += item.duration;
                } else {
                  obj.holidayNum.push(item.userId);
                  obj.holidayLength += item.duration;
                }
              } else {
                let yearNum = [],
                  holidayNum = [],
                  businessNum = [],
                  outNum = [],
                  yearLength = 0,
                  holidayLength = 0,
                  businessLength = 0,
                  outLength = 0;

                if (item.type === "on_business_out") {
                  businessNum.push(item.userId);
                  businessLength += item.duration;
                } else if (item.type === "on_business_in") {
                  outNum.push(item.userId);
                  outLength += item.duration;

                  if (item.durationUnit === "day") {
                    outLength += item.duration;
                  } else if (item.durationUnit === "hour") {
                    outLength += item.duration / 24;
                  }
                } else if (item.type === "annual") {
                  yearNum.push(item.userId);
                  yearLength += item.duration;
                } else {
                  holidayNum.push(item.userId);
                  holidayLength += item.duration;
                }

                depts.push({
                  deptName: item.deptName,
                  yearNum,
                  holidayNum,
                  businessNum,
                  outNum,
                  yearLength,
                  holidayLength,
                  businessLength,
                  outLength: parseInt(outLength),
                });
              }
            });

            this.depts = depts;

            const users = [];
            this.list.forEach((item) => {
              const obj = users.find(
                (element) => element.userId === item.userId
              );
              if (obj) {
                if (item.type === "on_business_out") {
                  obj.businessNum++;
                  obj.businessLength += item.duration;
                } else if (item.type === "on_business_in") {
                  obj.outNum++;
                  obj.outLength += item.duration;
                } else if (item.type === "annual") {
                  obj.yearNum++;
                  obj.yearLength += item.duration;
                } else {
                  obj.holidayNum++;
                  obj.holidayLength += item.duration;
                }
              } else {
                let yearNum = 0,
                  holidayNum = 0,
                  businessNum = 0,
                  outNum = 0,
                  yearLength = 0,
                  holidayLength = 0,
                  businessLength = 0,
                  outLength = 0;

                if (item.type === "on_business_out") {
                  businessNum++;
                  businessLength += item.duration;
                } else if (item.type === "on_business_in") {
                  outNum++;
                  outLength += item.duration;
                } else if (item.type === "annual") {
                  yearNum++;
                  yearLength += item.duration;
                } else {
                  holidayNum++;
                  holidayLength += item.duration;
                }

                users.push({
                  id: item.id,
                  userId: item.userId,
                  name: item.name,
                  deptName: item.deptName,
                  yearNum,
                  holidayNum,
                  businessNum,
                  outNum,
                  yearLength,
                  holidayLength,
                  businessLength,
                  outLength,
                });
              }
            });

            this.users = users;
          }
        })
        .finally(() => {
          hide();
        });
    },

    downloadNumChart() {
      const that = this;
      domtoimage
        .toBlob(document.getElementById("holiday-num"))
        .then(function (blob) {
          saveAs(
            blob,
            that.currentMonth.format("M") + "月各部门请假出差人数.png"
          );
        });
    },

    downloadLengthChart() {
      const that = this;
      domtoimage
        .toBlob(document.getElementById("holiday-length"))
        .then(function (blob) {
          saveAs(
            blob,
            that.currentMonth.format("M") + "月各部门请假出差时长.png"
          );
        });
    },
  },
};
</script>


<style lang="less" scoped>
.card {
  border-radius: 4px;
  padding: 8px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    .title {
      font-weight: bold;
      font-size: 14px;
    }
  }
}
</style>