<template>
  <a-card class="card">
    <div class="header">
      <div class="title">员工分析数据</div>

      <div class="extra">
        <a-space>
          <a-input placeholder="部门" v-model="dept" />
          <a-input placeholder="姓名" v-model="name" />
          <a-button type="primary" @click="download">导出</a-button>
        </a-space>
      </div>
    </div>

    <Padding />

    <a-table
      bordered
      :data-source="filteredList"
      rowKey="id"
      :pagination="{
      showSizeChanger: true,
      pageSizeOptions: ['10', '50', '100', '200'],
    }"
    >
      <a-table-column key="deptName" title="部门" data-index="deptName"></a-table-column>
      <a-table-column key="name" title="姓名" data-index="name" align="center"></a-table-column>

      <a-table-column
        key="yearNum"
        title="年假次数"
        data-index="yearNum"
        align="center"
        :sorter="(x, y) => x.yearNum - y.yearNum"
      />

      <a-table-column
        key="yearLength"
        title="年假时长"
        data-index="yearLength"
        align="center"
        :sorter="(x, y) => x.yearLength - y.yearLength"
      />

      <a-table-column
        key="holidayNum"
        title="请假次数"
        data-index="holidayNum"
        align="center"
        :sorter="(x, y) => x.holidayNum - y.holidayNum"
      />

      <a-table-column
        key="holidayLength"
        title="请假时长"
        data-index="holidayLength"
        align="center"
        :sorter="(x, y) => x.holidayLength - y.holidayLength"
      />

      <a-table-column
        key="businessNum"
        title="出差次数"
        data-index="businessNum"
        align="center"
        :sorter="(x, y) => x.businessNum - y.businessNum"
      />

      <a-table-column
        key="businessLength"
        title="出差时长"
        data-index="businessLength"
        align="center"
        :sorter="(x, y) => x.businessLength - y.businessLength"
      />

      <a-table-column
        key="outNum"
        title="公出次数"
        data-index="outNum"
        align="center"
        :sorter="(x, y) => x.outNum - y.outNum"
      />

      <a-table-column
        key="outLength"
        title="公出时长"
        data-index="outLength"
        align="center"
        :sorter="(x, y) => x.outLength - y.outLength"
      />
    </a-table>
  </a-card>
</template>

<script>
import downloadByList from "@/utils/xlsx";

export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    month: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      name: "",
      dept: ""
    };
  },

  computed: {
    filteredList() {
      let result = this.list;
      if (this.name) {
        result = result.filter(
          item => item.name && item.name.indexOf(this.name) > -1
        );
      }
      if (this.dept) {
        result = result.filter(
          item => item.deptName && item.deptName.indexOf(this.dept) > -1
        );
      }

      return result;
    }
  },

  methods: {
    download() {
      const result = this.filteredList.map(item => {
        return {
          姓名: item.name,
          部门: item.deptName,

          年假次数: item.yearNum,
          "年假时长(天)": item.yearLength,

          请假次数: item.holidayNum,
          "请假时长(天)": item.holidayLength,

          出差次数: item.businessNum,
          "出差时长(天)": item.businessLength,

          公出次数: item.outNum,
          "公出时长(天)": item.outLength
        };
      });

      console.log("result", result);
      downloadByList(result, this.month + "月年假请假出差公出统计数据");
    }
  }
};
</script>

<style lang="less" scoped>
.card {
  border-radius: 4px;
  padding: 8px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    .title {
      font-weight: bold;
      font-size: 14px;
    }
  }
}
</style>