<template>
  <div>
    <div id="holiday-length"></div>
  </div>
</template>
  
  
<script>
import * as echarts from "echarts/core";
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent,
]);

export default {
  props: {
    list: {
      type: Array,
    },
  },

  watch: {
    list: {
      handler() {
        this.renderChart();
      },
      deep: true,
    },
  },

  mounted() {
    var chartDom = document.getElementById("holiday-length");
    chartDom.style.height = "40vh";
    chartDom.style.width = "100%";
    this.chart = echarts.init(chartDom);
    this.renderChart();
  },

  destroyed() {
    this.chart?.dispose();
  },

  methods: {
    renderChart() {
      if (!Array.isArray(this.list)) return;

      this.chart?.clear();

      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          valueFormatter: (value) => value + "天",
        },

        legend: {
          left: "center",
          top: "bottom",
          textStyle: {},
        },

        grid: {
          show: false,
          left: "7%",
          top: "15%",
          bottom: "20%",
          right: "3%",
        },

        xAxis: {
          type: "category",
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            // color: "#999",
          },
          data: this.list.map((item) => item.deptName),
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#999",
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#999",
            },
          },
          axisLabel: {
            // color: "#999",
            formatter: "{value}天",
          },

          // ...yAxis,
        },

        series: [
          {
            name: "年假",
            type: "bar",
            data: this.list.map((item) => item.yearLength),
            label: {
              show: false,
              formatter: "{c}",
              position: "top",
              color: "#999",
            },
          },
          {
            name: "请假",
            type: "bar",
            data: this.list.map((item) => item.holidayLength),

            label: {
              show: false,
              formatter: "{c}",
              position: "top",
              color: "#999",
            },
          },
          {
            name: "出差",
            type: "bar",
            data: this.list.map((item) => item.businessLength),

            label: {
              show: false,
              formatter: "{c}",
              position: "top",
            },
          },
          {
            name: "公出",
            type: "bar",
            data: this.list.map((item) => parseInt(item.outLength)),

            label: {
              show: false,
              formatter: "{c}",
              position: "top",
              color: "#999",
            },
          },
        ],
      };

      option && this.chart.setOption(option);
    },
  },
};
</script>