<template>
  <div>
    <a-card class="card">
      <div class="header">
        <div class="title">员工数据</div>

        <div class="extra">
          <a-space>
            <a-input placeholder="部门" v-model="dept" />
            <a-input placeholder="姓名" v-model="name" />
            <a-select
              style="width: 180px"
              v-model="type"
              placeholder="类型"
              :allowClear="false"
            >
              <a-select-option
                v-for="item in typeList"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
            <a-button type="primary" @click="download">导出</a-button>
          </a-space>
        </div>
      </div>

      <Padding />

      <a-table
        bordered
        :data-source="filteredList"
        rowKey="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '200'],
        }"
      >
        <a-table-column
          key="deptName"
          title="部门"
          data-index="deptName"
        ></a-table-column>
        <a-table-column
          key="name"
          title="姓名"
          data-index="name"
          align="center"
        ></a-table-column>
        <a-table-column key="type" title="类型" align="center">
          <template slot-scope="text">
            <a-tag color="orange" v-if="text.type === 'on_business_in'">{{
              getName(text.type)
            }}</a-tag>
            <a-tag color="green" v-else-if="text.type === 'on_business_out'">{{
              getName(text.type)
            }}</a-tag>

            <a-tag color="orange" v-else>{{ getName(text.type) }}</a-tag>
          </template>
        </a-table-column>

        <a-table-column
          key="startTime"
          title="开始时间"
          data-index="startTime"
          align="center"
        />
        <a-table-column
          key="endTime"
          title="结束时间"
          data-index="endTime"
          align="center"
        />

        <a-table-column key="duration" title="时长" align="right">
          <template slot-scope="text">
            <span style="color: #1890ff"
              >{{ text.duration }}
              {{ text.durationUnit === "hour" ? "小时" : "天" }}
            </span>
          </template>
        </a-table-column>

        <a-table-column key="status" title="状态" align="center">
          <template slot-scope="text">
            <DataDictFinder
              dictType="taskReviewStatus"
              :dictValue="text.status"
              iconType="badge"
              badgeType="color"
            />
          </template>
        </a-table-column>

        <a-table-column
          key="createAt"
          title="创建时间"
          data-index="createAt"
          align="center"
        ></a-table-column>

        <a-table-column key="control" title="操作" align="center">
          <template slot-scope="text">
            <a href="#" @click.prevent="openDetail(text)">详情</a>
          </template>
        </a-table-column>
      </a-table>

      <a-modal
        :visible="visible"
        title="详情"
        :footer="null"
        @cancel="cancel"
        width="640px"
      >
        <a-descriptions bordered :column="2">
          <a-descriptions-item label="姓名">{{
            detail.name
          }}</a-descriptions-item>
          <a-descriptions-item label="部门">{{
            detail.deptName
          }}</a-descriptions-item>
          <a-descriptions-item label="类型">
            <a-tag color="orange" v-if="detail.type === 'on_business_in'">{{
              getName(detail.type)
            }}</a-tag>
            <a-tag
              color="green"
              v-else-if="detail.type === 'on_business_out'"
              >{{ getName(detail.type) }}</a-tag
            >

            <a-tag color="orange" v-else>{{ getName(detail.type) }}</a-tag>
          </a-descriptions-item>
          <a-descriptions-item label="时长"
            >{{ detail.duration }}
            {{ detail.durationUnit === "hour" ? "小时" : "天" }}
          </a-descriptions-item>

          <a-descriptions-item label="开始时间">{{
            detail.startTime
          }}</a-descriptions-item>
          <a-descriptions-item label="结束时间">{{
            detail.endTime
          }}</a-descriptions-item>
          <a-descriptions-item label="状态">
            <DataDictFinder
              dictType="taskReviewStatus"
              :dictValue="detail.status"
              iconType="badge"
              badgeType="color"
            />
          </a-descriptions-item>

          <a-descriptions-item label="创建时间">{{
            detail.createAt
          }}</a-descriptions-item>

          <template v-if="detail.type === 'on_business_out'">
            <a-descriptions-item label="出差地点">{{
              detail.targetAddr
            }}</a-descriptions-item>
            <a-descriptions-item label="费用预算"
              >{{ detail.cost }}元</a-descriptions-item
            >
            <a-descriptions-item label="交通工具(往)">{{
              detail.trafficTo
            }}</a-descriptions-item>
            <a-descriptions-item label="交通工具(返)">{{
              detail.trafficBack
            }}</a-descriptions-item>
            <a-descriptions-item label="出差事由" :span="2">{{
              detail.remark
            }}</a-descriptions-item>
            <a-descriptions-item label="同行人员" :span="2">{{
              Array.isArray(detail.staffList)
                ? detail.staffList.map((item) => item.userName).join("，")
                : "--"
            }}</a-descriptions-item>
          </template>

          <template v-else-if="detail.type === 'on_business_in'">
            <a-descriptions-item label="公出地点" :span="2">{{
              detail.targetAddr
            }}</a-descriptions-item>
            <a-descriptions-item label="公出事由" :span="2">{{
              detail.remark
            }}</a-descriptions-item>
          </template>
          <template v-else>
            <a-descriptions-item label="情况说明" :span="2">{{
              detail.remark
            }}</a-descriptions-item>
          </template>

          <a-descriptions-item
            label="附件"
            :span="2"
            v-if="attachments.length > 0"
          >
            <span v-for="(file, index) in attachments" :key="file">
              <a :href="file" target="_blank">{{ $getFileName(file) }}</a>
              <span
                style="color: #1890ff"
                v-if="index !== attachments.length - 1"
                >,</span
              >
            </span>
          </a-descriptions-item>
        </a-descriptions>
        <div style="padding-top: 16px; padding-bottom: 16px; font-weight: bold">
          审批流程
        </div>
        <HolidayLog :list="log" />
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import downloadByList from "@/utils/xlsx";

import { getName } from "@/utils/clock";

import { fetchLeaveReview } from "@/api/human-resources/clock";

import HolidayLog from "./holiday-log.vue";

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    month: {
      type: String,
      default: "",
    },
  },

  components: {
    HolidayLog,
  },

  data() {
    return {
      name: "",
      dept: "",
      type: undefined,
      typeList: [
        {
          name: "年假",
          value: "annual",
        },
        {
          name: "事假",
          value: "personal",
        },
        {
          name: "病假",
          value: "sick",
        },
        {
          name: "婚假",
          value: "marriage",
        },
        {
          name: "产假",
          value: "maternity",
        },
        {
          name: "产检假",
          value: "prenatal_check_up",
        },

        {
          name: "哺乳假",
          value: "breast_feeding",
        },
        {
          name: "护理假",
          value: "paternity",
        },
        {
          name: "工伤假",
          value: "work_related_injury",
        },
        {
          name: "丧假",
          value: "bereavement",
        },
        {
          name: "出差",
          value: "on_business_out",
        },
        {
          name: "公出",
          value: "on_business_in",
        },
      ],

      detail: {},
      visible: false,
      log: [],
    };
  },

  computed: {
    filteredList() {
      let result = this.list;
      if (this.name) {
        result = result.filter(
          (item) => item.name && item.name.indexOf(this.name) > -1
        );
      }
      if (this.dept) {
        result = result.filter(
          (item) => item.deptName && item.deptName.indexOf(this.dept) > -1
        );
      }

      if (this.type) {
        result = result.filter(
          (item) => item.type && item.type.indexOf(this.type) > -1
        );
      }

      return result;
    },

    attachments() {
      return typeof this.detail.attachments === "string"
        ? this.detail.attachments.split(",")
        : [];
    },
  },

  methods: {
    getName,

    openDetail(text) {
      this.detail = text;
      this.visible = true;

      fetchLeaveReview({
        id: text.id,
        type: text.type,
      }).then((res) => {
        console.log("res", res);
        if (Array.isArray(res)) {
          this.log = res;
        }
      });
    },

    cancel() {
      this.detail = {};
      this.visible = false;
      this.log = [];
    },

    download() {
      const result = this.filteredList.map((item) => {
        return {
          姓名: item.name,
          部门: item.deptName,
          类型: getName(item.type),
          "时长(天)": item.duration,
          开始时间: item.startTime,
          结束时间: item.endTime,
          创建时间: item.createAt,
        };
      });

      console.log("result", result);
      downloadByList(result, this.month + "月年假请假出差公出数据");
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  border-radius: 4px;
  padding: 8px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    .title {
      font-weight: bold;
      font-size: 14px;
    }
  }
}
</style>