<template>
  <div>
    <a-timeline>
      <a-timeline-item v-for="item in list" :key="item.id">
        <div class="between" style="margin-bottom: 8px">
          <span>{{ item.assigneeName}}</span>
          <DataDictFinder
            dictType="taskStatus"
            :dictValue="item.reviewOperation"
            iconType="badge"
            badgeType="color"
          />
        </div>
        <div style="margin-bottom: 8px" v-if="item.reviewRemarks">审批意见：{{item.reviewRemarks}}</div>
        <div class="between">
          <div>{{item.name}}</div>
          <div>{{ item. endAt ? item.endAt : item.createAt}}</div>
        </div>

        <a-icon v-if="!item.endAt" slot="dot" type="clock-circle-o" />
      </a-timeline-item>
    </a-timeline>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
};
</script>
